body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.list-item-edit {
    cursor: pointer;
}

.custom-tooltip {
    background-color: rgba(200, 200, 200, 0.7);
    padding: 10px;
}

.custom-tooltip .label {
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.bar-chart-title {
    margin-top: 0;
    text-align: center;
    font-style: italic;
}