// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
@import './scss/variables';
@import './scss/theme';

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/variables-dark";

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/helpers";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/close";
@import "../node_modules/bootstrap/scss/accordion";
@import "../node_modules/bootstrap/scss/transitions"; // required for accordions to work properly
@import "../node_modules/bootstrap/scss/helpers/stacks";
@import "../node_modules/bootstrap/scss/list-group";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/offcanvas";
@import "../node_modules/bootstrap/scss/nav";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "../node_modules/bootstrap/scss/utilities/api";

// 8. Add additional custom code here
// styles specific to the app
@import './scss/components';

// additional colors
$earnings_color: $primary;
$expenses_color: $pink-600;
.text-earnings {
    color: $earnings_color;
}
.text-expenses {
    color: $expenses_color;
}
.recharts-line.history-movements-chartline path {
    stroke: $primary;
}
.recharts-line.history-assets-chartline path {
    stroke: $secondary;
}

.stats-bar-earnings {
    fill: $primary;
}
.stats-bar-expenses {
    fill: $expenses_color;
}
.stats-bar-totals {
    fill: $secondary;
}
.movement-list {
    .category, .amount {
        &.expenses {
            color: $expenses_color;
        }
    
        &.earnings {
            color: $earnings_color;
        }
    }
    .category {
        width: 70%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .amount {
        font-weight: bold;
        font-size: large;
    }
}

.btn-transparent {
    background-color: unset;
    border: unset;
}
.trading-gain {
    &.gain {
    color: $primary;
    }
    &.loss {
    color: $pink-600;
    }
}
.settings-card-item {
    svg {
        max-width: 100px;
        margin-bottom: 1rem;
        path{
            fill: $secondary;
        }
    }
}